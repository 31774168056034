<script setup lang="ts">
// This component is a thin wrapper around BaseHeading to make sure we only
// use a few different title styles in our app, and makes it easier to change them
// if necessary.

// Right now we have omly two title sizes / styles.
// Large is used for content pages. Default for all others.
type TitleStyle = 'large' | 'default'

const { titleStyle = 'default', description = undefined } = defineProps<{ titleStyle?: TitleStyle, description?: string }>()

// TODO: legacy only?
const allowLargeTitles = false

const size = computed(() => {
  if (titleStyle === 'large' && allowLargeTitles) {
    return '5xl'
  }
  else {
    return 'xl'
  }
})
</script>

<template>
  <div class="mb-4">
    <BaseHeading
      :size="size"
      as="h2"
    >
      <slot />
    </BaseHeading>
    <BaseParagraph v-if="description">
      {{ description }}
    </BaseParagraph>
  </div>
</template>
